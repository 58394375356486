body,
html {
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to bottom, #d3dadc 0%, #d6dddf 40%, #d1dbdf 100%);
  height: 100%;
}

.logo {
  background-image: url('img/neathimg.jpg');
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
}

.active {
  color: pink;
}

.card-group {
  display: flex;
  justify-content: space-around;
}

.card-container {
  margin: 4px;
}